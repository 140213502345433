import React, { useEffect, useState } from "react";
import { Page, Layout, Card, TextField } from "@shopify/polaris";
import { ContextualSaveBar } from "@shopify/app-bridge-react";
import { NewShopForm } from "./support/NewShopForm";
import { CurrencyChange } from "./support/CurrencyChange";
import { InventorySyncOptions } from "./support/InventorySyncOptions";
import { SyncbackOptions } from "./support/SyncbackOptions";
import { CostSyncOptions } from "./support/CostSyncOptions";
import { updateUser, uploadLogo, useSettings } from "../../api_utils/requests";
import { ErrorBanner } from "../common/ErrorBanner";
import { Footer } from "../common/Footer";
import { SettingsPageSkeleton } from "../common/skeletons";
import { SETTINGS_HELP_PAGE_URL } from "../../constants";
import Toast from "../common/Toast";
import { extractMessageFromError } from "../../helper_functions/utils";
import type { User } from "../../api_utils/types";
import UploadBrandLogo from "./UploadBrandLogo";

function Settings(): React.ReactElement {
  const [isDirty, setIsDirty] = useState(false);
  const [brandFile, setBrandFile] = useState<File>(null);
  const [user, setUser] = useState<User>(null);
  const [originalUser, setOriginalUser] = useState<User>(null);

  const [errorMessage, setErrorMessage] = useState("");
  const [toastMessage, setToastMessage] = useState<string>(null);

  const {
    data: { currentUser, currencies, shopifyShops },
    isLoading: pageLoading,
  } = useSettings();

  useEffect(() => {
    setOriginalUser(currentUser);
    setUser(currentUser);
  }, [currentUser]);

  const handleSave = (): void => {
    setIsDirty(false);
    void handleUpload();
  };

  const handleUpload = async (): Promise<void> => {
    if (brandFile) {
      const formData = new FormData();
      formData.append("brandLogo", brandFile);
      await uploadLogo(currentUser.id, formData)
        .then((response) => {
          setUser({ ...user, brandLogo: response.brandLogo });
          // passing brand logo url beacuse we can't get the updated state right after pushing the data in it
          updateUserData(response.brandLogo);
        })
        .catch((err: unknown) => {
          Rollbar.error(err);
          const newErrorMessage = extractMessageFromError(err);
          setErrorMessage(newErrorMessage);
        });
    } else {
      updateUserData();
    }
  };

  const updateUserData = (aws_image_url: string): void => {
    updateUser(currentUser.id, {
      currency: user?.currency,
      company_name: user?.companyName,
      company_address: user?.companyAddress,
      sync_level: user?.syncLevel,
      syncback_setting: user?.syncbackSetting,
      costsync_setting: user?.costsyncSetting,
      brand_logo: aws_image_url,
      email: user?.email,
    })
      .then((response) => {
        setToastMessage("Updated");
        setUser(response.user);
        setOriginalUser(response.user);
      })
      .catch((err: unknown) => {
        Rollbar.error(err, user);
        const message = extractMessageFromError(err);
        setErrorMessage(message);
      });
  };

  const handleDiscard = (): void => {
    setUser(originalUser);
    setIsDirty(false);
  };

  const handleSyncOptionChange = (_checked: boolean, newValue: string): void => {
    setUser({ ...user, syncLevel: newValue });
    setIsDirty(true);
  };

  const handleSyncbackOptionChange = (_checked: boolean, newValue: string): void => {
    setUser({ ...user, syncbackSetting: newValue });
    setIsDirty(true);
  };

  const handleCostsyncOptionChange = (_checked: boolean, newValue: string): void => {
    setUser({ ...user, costsyncSetting: newValue });
    setIsDirty(true);
  };

  const handleCurrencyChange = (value: string): void => {
    setUser({ ...user, currency: value });
    setIsDirty(true);
  };

  const handleCompanyDetails = (value: string, id: string): void => {
    if (id === "company_name") {
      setUser({ ...user, companyName: value });
    } else {
      setUser({ ...user, companyAddress: value });
    }
    setIsDirty(true);
  };

  const handleEmailChange = (value: string): void => {
    setUser({ ...user, email: value });
    setIsDirty(true);
  };

  const pageMarkup = currentUser && (
    <Page title="Settings">
      <Toast setToastMessage={setToastMessage} toastMessage={toastMessage} />
      <ContextualSaveBar
        discardAction={{
          onAction: handleDiscard,
        }}
        saveAction={{
          onAction: handleSave,
        }}
        visible={isDirty}
      />
      <Layout>
        {errorMessage ? (
          <Layout.Section variant="fullWidth">
            <ErrorBanner errorMessage={errorMessage} setErrorMessage={setErrorMessage} />
          </Layout.Section>
        ) : null}
        <Layout.AnnotatedSection
          description="This is the currency that all values will appear as."
          title="Currency option"
        >
          <CurrencyChange
            currencies={currencies}
            currency={user?.currency}
            handleCurrencyChange={handleCurrencyChange}
          />
        </Layout.AnnotatedSection>
        <br />
        <Layout.AnnotatedSection description="Choose how Horse should sync to Shopify" title="Sync options">
          <InventorySyncOptions
            handleSyncOptionChange={handleSyncOptionChange}
            shopifyShops={shopifyShops}
            userSyncOption={user?.syncLevel}
          />
          <SyncbackOptions
            handleSyncOptionChange={handleSyncbackOptionChange}
            syncbackSetting={user?.syncbackSetting}
          />
          <CostSyncOptions
            costsyncSetting={user?.costsyncSetting}
            handleSyncOptionChange={handleCostsyncOptionChange}
          />
        </Layout.AnnotatedSection>
        <br />
        <Layout.AnnotatedSection
          description="Connect multiple stores for them to share inventory."
          title="Connected stores"
        >
          <NewShopForm currentUser={currentUser} shopifyShops={shopifyShops} />
        </Layout.AnnotatedSection>
        <br />
        <Layout.AnnotatedSection description="" title="Company Details">
          <Card>
            <TextField
              autoComplete="off"
              id="company_name"
              label="Company name"
              onChange={handleCompanyDetails}
              value={user?.companyName}
            />
            <br />
            <TextField
              autoComplete="off"
              id="company_address"
              label="Company Address"
              onChange={handleCompanyDetails}
              value={user?.companyAddress}
            />
            <br />
            <UploadBrandLogo
              brandFile={brandFile}
              brandLogo={user?.brandLogo}
              setBrandFile={setBrandFile}
              setIsDirty={setIsDirty}
            />
          </Card>
        </Layout.AnnotatedSection>
        <br />
        <Layout.AnnotatedSection description="" title="User Details">
          <Card>
            <TextField
              autoComplete="off"
              label="Email Address"
              onChange={handleEmailChange}
              type="email"
              value={user?.email}
            />
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
      <Footer pageTitle="settings" url={SETTINGS_HELP_PAGE_URL} />
    </Page>
  );

  return pageLoading ? <SettingsPageSkeleton /> : pageMarkup;
}

export default Settings;
