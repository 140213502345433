import React, { useState, useCallback, useEffect } from "react";
import qs from "query-string";
import type { SortButtonChoice, AppliedFilterInterface } from "@shopify/polaris";
import { Select, IndexFilters, useSetIndexFiltersMode, ChoiceList } from "@shopify/polaris";
import { getCurrencySymbol, isEmpty, softAssertNumber } from "../../helper_functions/utils";
import {
  allSuppliers,
  allTypes,
  allVendors,
  forceNumber,
  useHorseVariantsProductTypeOptions,
  useHorseVariantVendorsOptions,
  useShopifyChannelAvailabilityOptions,
  useSuppliersSearchOptions,
  useUser,
} from "../../api_utils/requests";
import { DualThumbRangeSlider } from "./support/DualThumbRangeSlider";
import MoreLessFilter from "../common/Filters/MoreLessFilter";
import TagCheckList from "../common/Filters/TagCheckList";
import type {
  ArchivedChoice,
  DecimalRangeFilter,
  NumberRangeFilter,
  SalesTrendsQueryParams,
} from "../../api_utils/types";
import { moreThanLessThanLabel } from "../common/Filters/label_tools";

const horseVariantArchiveStatuses = [
  { value: "unarchived", label: "Hide archived" },
  { value: "all", label: "Show archived" },
];

const stockInfoOptions = [
  { label: "All", value: "all" },
  { label: "Never available", value: "never_available" },
  { label: "No sales", value: "no_sales" },
  { label: "Out of stock", value: "out_of_stock" },
  { label: "Out of stock quite soon (<30 days)", value: "out_of_stock_quite_soon" },
  { label: "Out of stock soon (<60 days)", value: "out_of_stock_soon_ish" },
  { label: "Overstock", value: "overstock" },
];

const defaultSortOptions = [
  { label: "Title", directionLabel: "A-Z", value: "title asc" },
  { label: "Title", directionLabel: "Z-A", value: "title desc" },
  { label: "SKU", directionLabel: "A-Z", value: "sku asc" },
  { label: "SKU", directionLabel: "Z-A", value: "sku desc" },
  { label: "Vendor", directionLabel: "A-Z", value: "vendor asc" },
  { label: "Vendor", directionLabel: "Z-A", value: "vendor desc" },
  { label: "Product type", directionLabel: "A-Z", value: "product_type asc" },
  { label: "Product type", directionLabel: "Z-A", value: "product_type desc" },
  { label: "Available", directionLabel: "Small/Big", value: "currently_available asc" },
  { label: "Available", directionLabel: "Big/Small", value: "currently_available desc" },
  { label: "Quantity ordered", directionLabel: "Small/Big", value: "ordered_quantity asc" },
  { label: "Quantity ordered", directionLabel: "Big/Small", value: "ordered_quantity desc" },
  { label: "Days available", directionLabel: "Small/Big", value: "available_days asc" },
  { label: "Days available", directionLabel: "Big/Small", value: "available_days desc" },
  { label: "Sales", directionLabel: "Small/Big", value: "sales_quantity asc" },
  { label: "Sales", directionLabel: "Big/Small", value: "sales_quantity desc" },
  { label: "Sales rate", directionLabel: "Small/Big", value: "sales_rate asc" },
  { label: "Sales rate", directionLabel: "Big/Small", value: "sales_rate desc" },
  { label: "Days remaining", directionLabel: "Small/Big", value: "estimated_days_of_sales_remaining asc" },
  { label: "Days remaining", directionLabel: "Big/Small", value: "estimated_days_of_sales_remaining desc" },
] as SortButtonChoice[];

const TABS = {
  sales_rate: 0,
  seasonal: 1,
};

const COLLECTIONS = {
  [TABS.sales_rate]: "sales_rate",
  [TABS.seasonal]: "seasonal",
};

const tabs = [
  {
    // sends collection=undiscarded param to backend
    id: "sales-rate-query",
    content: "Sales rate",
    accessibilityLabel: "Sales rate result",
    panelID: "sales-rate-tab-content",
  },
  {
    // sends collection=all param to backend
    id: "seasonal-sales-query",
    content: "Seasonal",
    accessibilityLabel: "Seasonal sales",
    panelID: "seasonal-sales-tab-content",
  },
];

type DisambiguateLabelArgs =
  | {
      key: "horseLocationId";
      value: string;
      prefix?: never;
      label?: never;
      suffix?: never;
    }
  | {
      key: "horseVariantArchiveStatus";
      value: string;
      prefix?: never;
      label?: never;
      suffix?: never;
    }
  | {
      key: "moreThanLessThan";
      value: DecimalRangeFilter | NumberRangeFilter;
      prefix?: string;
      label: string;
      suffix?: string;
    }
  | {
      key: "productAvailability";
      value: string;
      prefix?: never;
      label?: never;
      suffix?: never;
    }
  | {
      key: "productTags";
      value: string[];
      prefix?: never;
      label?: never;
      suffix?: never;
    }
  | {
      key: "productType";
      value: string;
      prefix?: never;
      label?: never;
      suffix?: never;
    }
  | {
      key: "stockInfo";
      value: string;
      prefix?: never;
      label?: never;
      suffix?: never;
    }
  | {
      key: "supplierId";
      value: string;
      prefix?: never;
      label?: never;
      suffix?: never;
    }
  | {
      key: "vendor";
      value: string;
      prefix?: never;
      label?: never;
      suffix?: never;
    };

export default function SalesTrendsSearchFieldAndMoreFilters({
  setFilters,
  maxSalesRate,
  forecastingMethod,
  setForecastingMethod,
  samplingPeriod,
}: {
  readonly setFilters: (filters: Partial<SalesTrendsQueryParams>) => void;
  readonly maxSalesRate: number;
  readonly forecastingMethod: number;
  readonly setForecastingMethod: (method: number) => void;
  readonly samplingPeriod: string;
}): React.ReactElement {
  // Parse URL params
  const parsedUrlSearch = qs.parse(window.location.search);

  const initSortedCol =
    parsedUrlSearch["by_sort[column]"] || forecastingMethod === TABS.seasonal ? "sales_quantity" : "sales_rate";
  const initSortedColDir = (parsedUrlSearch["by_sort[direction]"] as string) || "desc";
  const [sortValue, setSortValue] = useState([`${initSortedCol} ${initSortedColDir}`]);

  const [search, setSearch] = useState<string>(parsedUrlSearch.search as string);
  const [vendor, setVendor] = useState<string>(parsedUrlSearch.vendor as string);
  const [supplierId, setSupplierId] = useState<string>(parsedUrlSearch.supplier_id as string);
  const [productType, setProductType] = useState<string>(parsedUrlSearch.product_type as string);
  const [stockInfo, setStockInfo] = useState<string>(parsedUrlSearch.info_query as string);
  const [horseVariantArchiveStatus, setHorseVariantArchiveStatus] = useState<ArchivedChoice>(
    (parsedUrlSearch.unarchived_horse_variants as ArchivedChoice) || "unarchived",
  );
  const initProductTags =
    typeof parsedUrlSearch["product_tags[]"] === "string"
      ? parsedUrlSearch["product_tags[]"].split(",")
      : parsedUrlSearch["product_tags[]"];
  const [productTags, setProductTags] = useState(initProductTags || []);
  const [productAvailability, setProductAvailability] = useState("");
  const [orderedQuantity, setOrderedQuantity] = useState<DecimalRangeFilter>({
    more_than: parsedUrlSearch["by_ordered_quantity[more_than]"] as string,
    less_than: parsedUrlSearch["by_ordered_quantity[less_than]"] as string,
  });
  const [cost, setCost] = useState<DecimalRangeFilter>({
    more_than: parsedUrlSearch["by_cost[more_than]"] as string,
    less_than: parsedUrlSearch["by_cost[less_than]"] as string,
  });
  const [salesQuantity, setSalesQuantity] = useState<DecimalRangeFilter>({
    more_than: parsedUrlSearch["by_sales_quantity[more_than]"] as string,
    less_than: parsedUrlSearch["by_sales_quantity[less_than]"] as string,
  });
  const [salesRate, setSalesRate] = useState<NumberRangeFilter>({
    more_than: softAssertNumber(parsedUrlSearch["by_sales_rate[more_than]"] as string),
    less_than: softAssertNumber(parsedUrlSearch["by_sales_rate[less_than]"] as string),
  });
  const [weight, setWeight] = useState<DecimalRangeFilter>({
    more_than: parsedUrlSearch["by_weight[more_than]"] as string,
    less_than: parsedUrlSearch["by_weight[less_than]"] as string,
  });
  const [currentlyAvailable, setCurrentlyAvailable] = useState<DecimalRangeFilter>({
    more_than: parsedUrlSearch["by_currently_available[more_than]"] as string,
    less_than: parsedUrlSearch["by_currently_available[less_than]"] as string,
  });
  const [availableDays, setAvailableDays] = useState<DecimalRangeFilter>({
    more_than: parsedUrlSearch["by_available_days[more_than]"] as string,
    less_than: parsedUrlSearch["by_available_days[less_than]"] as string,
  });

  // Fetch options
  const collection = horseVariantArchiveStatus === "unarchived" ? "undiscarded" : "all";
  const { data: productTypes } = useHorseVariantsProductTypeOptions({ collection });
  const { data: shopifyChannels } = useShopifyChannelAvailabilityOptions();
  const { data: suppliers } = useSuppliersSearchOptions();
  const { data: vendors } = useHorseVariantVendorsOptions({ collection });

  const {
    data: {
      user: { currency: userCurrency },
    },
  } = useUser();

  const [sortOptions, setSortOptions] = useState(defaultSortOptions);
  useEffect(() => {
    if (forecastingMethod === TABS.seasonal) {
      const salesRateBigToSmall = defaultSortOptions.findIndex((filter) => filter.label === "Sales rate small to big");
      const salesRateSmallToBig = defaultSortOptions.findIndex((filter) => filter.label === "Sales rate small to big");
      if (salesRateBigToSmall !== -1) {
        sortOptions.splice(salesRateBigToSmall, 1);
      }
      if (salesRateSmallToBig !== -1) {
        sortOptions.splice(salesRateSmallToBig, 1);
      }
      setSortOptions(sortOptions);
    } else {
      setSortOptions(defaultSortOptions);
    }
  }, [forecastingMethod, sortOptions]);

  // Listen for filter changes
  useEffect(() => {
    const [column_name, direction] = sortValue[0].split(" ");
    const shopifyChannelOption = shopifyChannels.find((channel) => channel.value === productAvailability);
    setFilters({
      vendor,
      supplier_id: forceNumber(supplierId),
      product_type: productType,
      available: shopifyChannelOption?.type === "available" ? shopifyChannelOption.shopifyChannelId : undefined,
      unavailable: shopifyChannelOption?.type === "unavailable" ? shopifyChannelOption.shopifyChannelId : undefined,
      unarchived_horse_variants: horseVariantArchiveStatus,
      info_query: stockInfo,
      forecasting_method: COLLECTIONS[forecastingMethod],
      sampling_period: samplingPeriod,
      search,

      product_tags: productTags,
      by_ordered_quantity: {
        more_than: forceNumber(orderedQuantity.more_than),
        less_than: forceNumber(orderedQuantity.less_than),
      },
      by_cost: { more_than: forceNumber(cost.more_than), less_than: forceNumber(cost.less_than) },
      by_sales_quantity: {
        more_than: forceNumber(salesQuantity.more_than),
        less_than: forceNumber(salesQuantity.less_than),
      },
      by_sales_rate: { more_than: forceNumber(salesRate.more_than), less_than: forceNumber(salesRate.less_than) },
      by_weight: { more_than: forceNumber(weight.more_than), less_than: forceNumber(weight.less_than) },
      by_currently_available: {
        more_than: forceNumber(currentlyAvailable.more_than),
        less_than: forceNumber(currentlyAvailable.less_than),
      },
      by_available_days: {
        more_than: forceNumber(availableDays.more_than),
        less_than: forceNumber(availableDays.less_than),
      },

      by_sort: {
        column: column_name,
        // There seems to be a bug in Polaris that sends a number for direction on initial sort
        direction: typeof direction === "number" ? "asc" : direction,
      },
    });
  }, [
    setFilters,
    shopifyChannels,

    vendor,
    supplierId,
    productType,
    productAvailability,
    horseVariantArchiveStatus,
    stockInfo,
    forecastingMethod,
    samplingPeriod,
    search,

    productTags,

    orderedQuantity,
    cost,
    salesQuantity,
    salesRate,
    weight,
    currentlyAvailable,
    availableDays,

    sortValue,
  ]);

  // Handle individual filter changes
  const handleVendorChange = useCallback((value: string) => {
    setVendor(value === allVendors ? undefined : value);
  }, []);
  const handleSupplierChange = useCallback((value: string) => {
    setSupplierId(value === allSuppliers ? undefined : value);
  }, []);
  const handleProductTypeChange = useCallback((value: string) => {
    setProductType(value === allTypes ? undefined : value);
  }, []);
  const handleHorseVariantsArchiveChange = useCallback((value: ArchivedChoice) => {
    setHorseVariantArchiveStatus(value);
  }, []);
  const handleStockInfoChange = useCallback((value: string) => {
    setStockInfo(value === "all" ? undefined : value);
  }, []);

  const handleNewTagAddition = useCallback(
    (newTag: string): void => {
      const payload = [...productTags, newTag];
      setProductTags(payload);
    },
    [productTags],
  );

  const handleSort = useCallback((column_name_and_direction: string[]) => {
    setSortValue(column_name_and_direction);
  }, []);

  // Removal handlers
  const handleVendorRemove = useCallback(() => {
    handleVendorChange(undefined);
  }, [handleVendorChange]);
  const handleSupplierIdRemove = useCallback(() => {
    handleSupplierChange(undefined);
  }, [handleSupplierChange]);
  const handleProductTypeRemove = useCallback(() => {
    handleProductTypeChange(undefined);
  }, [handleProductTypeChange]);
  const handleProductAvailabilityRemove = useCallback(() => {
    setProductAvailability("");
  }, []);
  const handleHorseVariantArchiveStatusRemove = useCallback(() => {
    handleHorseVariantsArchiveChange("unarchived");
  }, [handleHorseVariantsArchiveChange]);
  const handleStockInfoRemove = useCallback(() => {
    handleStockInfoChange(undefined);
  }, [handleStockInfoChange]);
  const handleSearchRemove = useCallback(() => {
    setSearch("");
  }, []);
  const handleProductTagsRemove = useCallback(() => {
    setProductTags([]);
  }, []);

  const handleFiltersClearAll = useCallback(() => {
    handleVendorRemove();
    handleSupplierIdRemove();
    handleProductTypeRemove();
    handleProductAvailabilityRemove();
    handleHorseVariantArchiveStatusRemove();
    handleStockInfoRemove();
    handleSearchRemove();
    handleProductTagsRemove();

    setOrderedQuantity({ more_than: undefined, less_than: undefined });
    setCost({ more_than: undefined, less_than: undefined });
    setSalesQuantity({ more_than: undefined, less_than: undefined });
    setSalesRate({ more_than: undefined, less_than: undefined });
    setWeight({ more_than: undefined, less_than: undefined });
    setCurrentlyAvailable({ more_than: undefined, less_than: undefined });
    setAvailableDays({ more_than: undefined, less_than: undefined });
  }, [
    handleVendorRemove,
    handleSupplierIdRemove,
    handleProductTypeRemove,
    handleProductAvailabilityRemove,
    handleHorseVariantArchiveStatusRemove,
    handleStockInfoRemove,
    handleSearchRemove,
    handleProductTagsRemove,
  ]);

  const filters = [
    {
      key: "stockInfo",
      label: "Stock info",
      filter: (
        <Select
          id="Stock-info_select"
          label=""
          labelHidden
          onChange={handleStockInfoChange}
          options={stockInfoOptions}
          value={stockInfo || "All"}
        />
      ),
    },
    {
      key: "horseVariantArchiveStatus",
      label: "Status",
      filter: (
        <Select
          id="inventory-level-histories_horse-variants_select"
          label=""
          labelHidden
          onChange={handleHorseVariantsArchiveChange}
          options={horseVariantArchiveStatuses}
          value={horseVariantArchiveStatus}
        />
      ),
    },
    {
      key: "vendor",
      label: "Product vendor",
      filter: (
        <Select
          id="inventory-level-histories_vendor_select"
          label=""
          labelHidden
          onChange={handleVendorChange}
          options={vendors}
          value={vendor || allVendors}
        />
      ),
    },
    {
      key: "supplierId",
      label: "Supplier",
      filter: (
        <Select
          id="inventory-level-histories_supplier_select"
          label=""
          labelHidden
          onChange={handleSupplierChange}
          options={suppliers}
          value={supplierId || allSuppliers}
        />
      ),
    },
    {
      key: "productType",
      label: "Product type",
      filter: (
        <Select
          id="inventory-level-histories_product-type_select"
          label=""
          labelHidden
          onChange={handleProductTypeChange}
          options={productTypes}
          value={productType || allTypes}
        />
      ),
    },
    {
      key: "cost",
      label: "Cost",
      filter: (
        <MoreLessFilter
          align="right"
          less_than={cost.less_than}
          more_than={cost.more_than}
          prefix={getCurrencySymbol(userCurrency)}
          setStateCallback={setCost}
          targetState={cost}
          type="currency"
        />
      ),
    },
    {
      key: "weight",
      label: "Weight",
      filter: (
        <MoreLessFilter
          less_than={weight.less_than}
          more_than={weight.more_than}
          setStateCallback={setWeight}
          suffix="g"
          targetState={weight}
          type="number"
        />
      ),
    },
    {
      key: "currentlyAvailable",
      label: "Current available",
      filter: (
        <MoreLessFilter
          less_than={currentlyAvailable.less_than}
          more_than={currentlyAvailable.more_than}
          setStateCallback={setCurrentlyAvailable}
          targetState={currentlyAvailable}
          type="integer"
        />
      ),
    },
    {
      key: "availableDays",
      label: "Days available",
      filter: (
        <MoreLessFilter
          less_than={availableDays.less_than}
          more_than={availableDays.more_than}
          setStateCallback={setAvailableDays}
          targetState={availableDays}
          type="integer"
        />
      ),
    },
    {
      key: "orderedQuantity",
      label: "Ordered quantity",
      filter: (
        <MoreLessFilter
          less_than={orderedQuantity.less_than}
          more_than={orderedQuantity.more_than}
          setStateCallback={setOrderedQuantity}
          targetState={orderedQuantity}
          type="integer"
        />
      ),
    },
    {
      key: "salesQuantity",
      label: "Total sales",
      filter: (
        <MoreLessFilter
          less_than={salesQuantity.less_than}
          more_than={salesQuantity.more_than}
          setStateCallback={setSalesQuantity}
          targetState={salesQuantity}
          type="currency"
        />
      ),
    },
    {
      key: "salesRate",
      label: "Sales rate",
      filter: (
        <DualThumbRangeSlider
          defaultMax={1.9}
          maxSalesRate={maxSalesRate}
          rangeValue={salesRate}
          setRangeValue={setSalesRate}
          step={0.1}
          suffix="per day"
        />
      ),
    },
    {
      key: "productAvailability",
      label: "Product publishing",
      filter: (
        <ChoiceList
          choices={shopifyChannels}
          onChange={useCallback(([value]) => {
            setProductAvailability(value);
          }, [])}
          selected={[productAvailability]}
          title=""
          titleHidden
        />
      ),
    },
    {
      key: "productTags",
      label: "Product tags",
      filter: <TagCheckList onAdd={handleNewTagAddition} onChange={setProductTags} selectedOptions={productTags} />,
    },
  ];

  const disambiguateLabel = useCallback(
    ({ key, value, label, prefix, suffix }: DisambiguateLabelArgs): string => {
      switch (key) {
        case "vendor":
          return `Vendor is ${value}`;
        case "supplierId":
          return `Supplier is ${suppliers.find((sup) => sup.value === value).label || value}`;
        case "productType":
          return `Product type is ${value}`;
        case "productAvailability":
          return value;
        case "horseVariantArchiveStatus":
          return "Show archived";
        case "stockInfo":
          return value;
        case "horseLocationId":
          return `Location is ${value}`;
        case "productTags":
          return `Product tags: ${value.join(", ")}`;
        case "moreThanLessThan":
          return moreThanLessThanLabel(value, label, prefix, suffix);
        default:
          throw new Error(`Unknown key: ${key}`);
      }
    },
    [suppliers],
  );

  const appliedFilters: AppliedFilterInterface[] = [];
  if (!isEmpty(vendor)) {
    appliedFilters.push({
      key: "vendor",
      label: disambiguateLabel({ key: "vendor", value: vendor }),
      onRemove: handleVendorRemove,
    });
  }
  if (!isEmpty(supplierId)) {
    appliedFilters.push({
      key: "supplierId",
      label: disambiguateLabel({ key: "supplierId", value: supplierId }),
      onRemove: handleSupplierIdRemove,
    });
  }
  if (!isEmpty(productType)) {
    appliedFilters.push({
      key: "productType",
      label: disambiguateLabel({ key: "productType", value: productType }),
      onRemove: handleProductTypeRemove,
    });
  }
  if (!isEmpty(productAvailability)) {
    const badgeLabel = shopifyChannels.find((channel) => channel.value === productAvailability).label;
    appliedFilters.push({
      key: "productAvailability",
      label: disambiguateLabel({ key: "productAvailability", value: badgeLabel }),
      onRemove: handleProductAvailabilityRemove,
    });
  }
  if (!isEmpty(horseVariantArchiveStatus) && horseVariantArchiveStatus !== "unarchived") {
    appliedFilters.push({
      key: "horseVariantArchiveStatus",
      label: disambiguateLabel({ key: "horseVariantArchiveStatus", value: horseVariantArchiveStatus }),
      onRemove: handleHorseVariantArchiveStatusRemove,
    });
  }
  if (!isEmpty(stockInfo)) {
    const stockInfoOption = stockInfoOptions.find((option) => option.value === stockInfo);
    appliedFilters.push({
      key: "stockInfo",
      label: disambiguateLabel({ key: "stockInfo", value: stockInfoOption.label }),
      onRemove: handleStockInfoRemove,
    });
  }
  if (!isEmpty(productTags)) {
    appliedFilters.push({
      key: "productTags",
      label: disambiguateLabel({ key: "productTags", value: productTags }),
      onRemove: handleProductTagsRemove,
    });
  }
  if (!isEmpty(orderedQuantity.more_than) || !isEmpty(orderedQuantity.less_than)) {
    appliedFilters.push({
      key: "orderedQuantity",
      label: disambiguateLabel({
        key: "moreThanLessThan",
        value: orderedQuantity,
        label: "Ordered quantity",
      }),
      onRemove: () => {
        setOrderedQuantity({ more_than: undefined, less_than: undefined });
      },
    });
  }
  if (!isEmpty(cost.more_than) || !isEmpty(cost.less_than)) {
    appliedFilters.push({
      key: "cost",
      label: disambiguateLabel({
        key: "moreThanLessThan",
        value: cost,
        label: "Cost",
        prefix: getCurrencySymbol(userCurrency),
      }),
      onRemove: () => {
        setCost({ more_than: undefined, less_than: undefined });
      },
    });
  }
  if (!isEmpty(salesQuantity.more_than) || !isEmpty(salesQuantity.less_than)) {
    appliedFilters.push({
      key: "salesQuantity",
      label: disambiguateLabel({ key: "moreThanLessThan", value: salesQuantity, label: "Sales" }),
      onRemove: () => {
        setSalesQuantity({ more_than: undefined, less_than: undefined });
      },
    });
  }
  if (!isEmpty(salesRate.more_than) || !isEmpty(salesRate.less_than)) {
    appliedFilters.push({
      key: "salesRate",
      label: disambiguateLabel({ key: "moreThanLessThan", value: salesRate, label: "Sales rate" }),
      onRemove: () => {
        setSalesRate({ more_than: undefined, less_than: undefined });
      },
    });
  }
  if (!isEmpty(weight.more_than) || !isEmpty(weight.less_than)) {
    appliedFilters.push({
      key: "weight",
      label: disambiguateLabel({ key: "moreThanLessThan", value: weight, label: "Weight", suffix: "g" }),
      onRemove: () => {
        setWeight({ more_than: undefined, less_than: undefined });
      },
    });
  }
  if (!isEmpty(currentlyAvailable.more_than) || !isEmpty(currentlyAvailable.less_than)) {
    appliedFilters.push({
      key: "currentlyAvailable",
      label: disambiguateLabel({ key: "moreThanLessThan", value: currentlyAvailable, label: "Currently available" }),
      onRemove: () => {
        setCurrentlyAvailable({ more_than: undefined, less_than: undefined });
      },
    });
  }
  if (!isEmpty(availableDays.more_than) || !isEmpty(availableDays.less_than)) {
    appliedFilters.push({
      key: "availableDays",
      label: disambiguateLabel({ key: "moreThanLessThan", value: availableDays, label: "Days available" }),
      onRemove: () => {
        setAvailableDays({ more_than: undefined, less_than: undefined });
      },
    });
  }

  const { mode, setMode } = useSetIndexFiltersMode();

  return (
    <IndexFilters
      appliedFilters={appliedFilters}
      canCreateNewView={false}
      cancelAction={{
        onAction: () => {},
        disabled: false,
        loading: false,
      }}
      filters={
        forecastingMethod === TABS.seasonal ? filters.filter((filter) => filter.label !== "Sales rate") : filters
      }
      mode={mode}
      onClearAll={handleFiltersClearAll}
      onQueryChange={setSearch}
      onQueryClear={handleSearchRemove}
      onSelect={setForecastingMethod}
      onSort={handleSort}
      queryPlaceholder="Search variants"
      queryValue={search}
      selected={forecastingMethod}
      setMode={setMode}
      sortOptions={sortOptions}
      sortSelected={sortValue}
      tabs={tabs}
    />
  );
}
