import React, { useEffect, useState } from "react";
import { Page } from "@shopify/polaris";
import criticalImage from "images/home/critical.svg";
import oosImage from "images/home/out-of-stock.svg";
import oossImage from "images/home/out-of-stock-soon.svg";
import osImage from "images/home/overstock.svg";
import BlocksContent from "./support/BlocksContent";
import ReviewBlock from "./support/ReviewBlock";
import Onboarding from "./support/Onboarding";
import ImportProgressBanner from "./support/ImportProgressBanner";
import EmptyStateComponent from "./support/EmptyStateComponent";
import {
  getHomeRecommendations,
  useBanners,
  useHorseLocationsOptions,
  useShopifyChannelSalesOptions,
} from "../../api_utils/requests";
import { Footer } from "../common/Footer";
import type { HomeRecommendation, HomeRecommendations } from "../../api_utils/types";
import type { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import type { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { HomeIndexPageSkeleton } from "../common/skeletons";

type DashboardType = "Stockouts" | "Out of stock soon" | "Overstock";

const importingEmptyState = (title: DashboardType): React.ReactElement => {
  switch (title) {
    case "Stockouts":
      return (
        <EmptyStateComponent
          header="Your information is still being imported"
          imageSrc={oosImage}
          infoMessage="We'll have advice about your stockouts soon."
          title={title}
        />
      );
    case "Out of stock soon":
      return (
        <EmptyStateComponent
          header="Your information is still being imported"
          imageSrc={oossImage}
          infoMessage="Your forecast about which products will be out of stock soon is being calculated."
          title={title}
        />
      );
    case "Overstock":
      return (
        <EmptyStateComponent
          header="Your information is still being imported"
          imageSrc={osImage}
          infoMessage="Hang tight while we figure out which variants you have too many of."
          title={title}
        />
      );
    default:
      throw new Error(`Invalid dashboard type`);
  }
};

const oosEmptyState = (
  <EmptyStateComponent
    header="All of your inventory is in-stock"
    imageSrc={oosImage}
    infoMessage="You haven’t lost any money due to lost sales."
    seeMoreUrl="/sales_trends"
    title="Stockouts"
  />
);
const oossEmptyState = (
  <EmptyStateComponent
    header="You're well stocked"
    imageSrc={oossImage}
    infoMessage="None of your inventory will sell out in the next 180 days."
    seeMoreUrl="/sales_trends"
    title="Out of stock soon"
  />
);
const osEmptyState = (
  <EmptyStateComponent
    header="You didn’t waste your capital"
    imageSrc={osImage}
    infoMessage="You’re being efficient with your capital and didn’t buy too much."
    seeMoreUrl="/sales_trends"
    title="Overstock"
  />
);

const outOfStockColumnNames: NonEmptyArray<IndexTableHeading> = [
  { title: "Variant" },
  { title: "Sold out date" },
  { title: "Lost sales" },
];
const oossFutureLostColumnNames: NonEmptyArray<IndexTableHeading> = [
  { title: "Variant" },
  { title: "Available" },
  { title: "Daily sales" },
];
const overStockColumnNames: NonEmptyArray<IndexTableHeading> = [
  { title: "Variant" },
  { title: "Available" },
  { title: "Frozen capital" },
];
const helpCenterUrl = "https://horse-inventory.notion.site/Help-Center-5ade9ae7bcd2430faf8945369b9af98f";

function HomeIndex(): React.ReactElement {
  const [outOfStockSoonFutureLost, setOutOfStockSoonFutureLost] = useState(0);
  const [outOfStockSoonRows, setOutOfStockSoonRows] = useState<HomeRecommendation[]>([]);
  const [outOfStockSoonActive, setOutOfStockSoonActive] = useState(false);
  const [outOfStockLost, setOutOfStockLost] = useState(0);
  const [outOfStockRows, setOutOfStockRows] = useState<HomeRecommendation[]>([]);
  const [outOfStockActive, setOutOfStockActive] = useState(false);
  const [overStockFrozen, setOverStockFrozen] = useState(0);
  const [overStockRows, setOverStockRows] = useState<HomeRecommendation[]>([]);
  const [overStockActive, setOverStockActive] = useState(false);
  const [query, setQuery] = useState<{
    horse_location_id: number[];
    shopify_channel_id: number[];
  }>({ horse_location_id: [], shopify_channel_id: [] });
  const [isLoading, setIsLoading] = useState(false);

  const { data: horseLocations } = useHorseLocationsOptions({ omitAll: true });
  const { data: shopifyChannels, isLoading: pageLoading } = useShopifyChannelSalesOptions();

  const setRowsAndTotalValue = (apiResp: HomeRecommendations): void => {
    setOutOfStockSoonActive(outOfStockSoonActive || apiResp.out_of_stock_soon.length > 0);
    setOutOfStockActive(outOfStockActive || apiResp.out_of_stock.length > 0);
    setOverStockActive(overStockActive || apiResp.over_stock.length > 0);

    setOutOfStockLost(apiResp.oos_lost);
    setOutOfStockSoonFutureLost(apiResp.ooss_future_lost);
    setOverStockFrozen(apiResp.os_frozen);

    setOutOfStockSoonRows(apiResp.out_of_stock_soon);
    setOutOfStockRows(apiResp.out_of_stock);
    setOverStockRows(apiResp.over_stock);
  };

  const handleSelectChange =
    (name: "horse_location_id" | "shopify_channel_id") =>
    (val: number[]): void => {
      setQuery({ ...query, [name]: val });
    };

  useEffect(() => {
    setIsLoading(true);
    getHomeRecommendations(query).then((res) => {
      setRowsAndTotalValue(res);
      setIsLoading(false);
    });
  }, [query]);

  const handleLocationSelectRemove = (horse_location_id: number): void => {
    const left = query.horse_location_id.filter((select) => select !== horse_location_id);
    setQuery({ ...query, horse_location_id: left });
  };

  const handleShopifyChannelSelectRemove = (shopify_channel_id: number): void => {
    const left = query.shopify_channel_id.filter((select) => select !== shopify_channel_id);
    setQuery({ ...query, shopify_channel_id: left });
  };

  const { data: banner } = useBanners();

  const renderOutOfStock = (): React.ReactElement => {
    if (outOfStockActive) {
      return (
        <BlocksContent
          amount={outOfStockLost}
          backgroundColor="#FED3D1"
          buttonUrl="/purchase_orders/new"
          columnNames={outOfStockColumnNames}
          handleLocationSelectRemove={handleLocationSelectRemove}
          handleSelectLocationChange={handleSelectChange("horse_location_id")}
          handleSelectShopifyChannelChange={handleSelectChange("shopify_channel_id")}
          handleShopifyChannelSelectRemove={handleShopifyChannelSelectRemove}
          header="Stockouts"
          horseLocations={horseLocations}
          horse_location_id={query.horse_location_id}
          imageSrc={criticalImage}
          info_one="These variants are out of stock. Consider ordering more so that you don’t lose any more sales."
          info_three="in sales due to out-of-stock variants!"
          info_two="You have lost an estimated"
          isLoading={isLoading}
          position={0}
          shopifyChannels={shopifyChannels}
          shopify_channel_id={query.shopify_channel_id}
          showPerDay={false}
          tableRows={outOfStockRows}
          url="/sales_trends?by_sales_quantity%5Bmore_than%5D=0&info_query=out_of_stock"
        />
      );
    } else if (banner?.info) {
      return importingEmptyState("Stockouts");
    } else {
      return oosEmptyState;
    }
  };

  const renderOutOfStockSoonRender = (): React.ReactElement => {
    if (outOfStockSoonActive) {
      return (
        <BlocksContent
          amount={outOfStockSoonFutureLost}
          backgroundColor="#FFF6DE"
          buttonUrl="/purchase_orders/new"
          columnNames={oossFutureLostColumnNames}
          handleLocationSelectRemove={handleLocationSelectRemove}
          handleSelectLocationChange={handleSelectChange("horse_location_id")}
          handleSelectShopifyChannelChange={handleSelectChange("shopify_channel_id")}
          handleShopifyChannelSelectRemove={handleShopifyChannelSelectRemove}
          header="Out of stock soon"
          horseLocations={horseLocations}
          horse_location_id={query.horse_location_id}
          imageSrc={criticalImage}
          info_one="These variants will run out of stock soon. Consider ordering more so that you don't lose any sales."
          info_three="if these variants go out-of-stock!"
          info_two="You could lose"
          isLoading={isLoading}
          position={1}
          shopifyChannels={shopifyChannels}
          shopify_channel_id={query.shopify_channel_id}
          showPerDay
          tableRows={outOfStockSoonRows}
          url="/sales_trends?info_query=out_of_stock_quite_soon"
        />
      );
    } else if (banner?.info) {
      return importingEmptyState("Out of stock soon");
    } else {
      return oossEmptyState;
    }
  };

  const renderOverStockRender = (): React.ReactElement => {
    if (overStockActive) {
      return (
        <BlocksContent
          amount={overStockFrozen}
          backgroundColor="#EAFAFF"
          columnNames={overStockColumnNames}
          handleLocationSelectRemove={handleLocationSelectRemove}
          handleSelectLocationChange={handleSelectChange("horse_location_id")}
          handleSelectShopifyChannelChange={handleSelectChange("shopify_channel_id")}
          handleShopifyChannelSelectRemove={handleShopifyChannelSelectRemove}
          header="Overstock"
          horseLocations={horseLocations}
          horse_location_id={query.horse_location_id}
          imageSrc={criticalImage}
          info_one="These variants aren’t selling very well.Unlock your frozen capital by discounting them so that they sell faster."
          info_three="in frozen capital."
          info_two="You have"
          isLoading={isLoading}
          position={2}
          shopifyChannels={shopifyChannels}
          shopify_channel_id={query.shopify_channel_id}
          showPerDay={false}
          tableRows={overStockRows}
          url="/sales_trends?info_query=overstock"
        />
      );
    } else if (banner?.info) {
      return importingEmptyState("Overstock");
    } else {
      return osEmptyState;
    }
  };

  const pageMarkup = (
    <Page narrowWidth>
      <Onboarding />
      <ImportProgressBanner />
      {renderOutOfStock()}
      {renderOutOfStockSoonRender()}
      {renderOverStockRender()}
      <ReviewBlock />
      <Footer pageTitle="Get help with Horse" url={helpCenterUrl} />
    </Page>
  );

  return pageLoading ? <HomeIndexPageSkeleton /> : pageMarkup;
}

export default HomeIndex;
