import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type {
  FormattedShopifyChannel,
  HorseInventoryLevel,
  IHorseVariant,
  Option,
  PaginatedResponse,
  SalesTrendsTotalsAndMax,
  ChildBundleHorseVariant,
  Bundle,
  IPurchaseOrder,
  IPurchaseOrderLineItem,
  QueryParams,
  HomeRecommendations,
  ShopifyVariant,
  HorseLocation,
  LinkedLocation,
  Supplier,
  SupplierHorseVariant,
  TransferOrder,
  TransferOrderLineItem,
  SalesTrendsQueryParams,
  NumberRangeFilter,
  CostAdjustment,
  InventoryLevelHistory,
  IndexPurchaseOrder,
  IndexTransferOrder,
  ISupplierSearchOption,
  CreatePurchaseOrder,
  User,
  UserResponse,
  ShopifyShop,
  UpdateUser,
  Banner,
} from "./types";

// /horse_inventory_levels
export const updateHorseInventoryLevels = async ({
  horse_variant_id,
  horse_inventory_levels,
  tracked,
}: {
  horse_variant_id: number;
  horse_inventory_levels: { id?: number; horse_location_id: number; horse_variant_id?: number; available: number }[];
  tracked: boolean;
}): Promise<HorseInventoryLevel[]> => {
  return await patch<HorseInventoryLevel[]>(`/horse_variants/${horse_variant_id}/horse_inventory_levels`, {
    horse_variant: {
      tracked,
    },
    horse_inventory_levels,
  });
};

export const getHorseInventoryLevels = async (queryParams: QueryParams): Promise<HorseInventoryLevel[]> => {
  return await get("/horse_inventory_levels.json", queryParams);
};

// /horse_locations
export const useHorseLocationsOptions = ({
  collection,
  omitAll = false,
}: {
  collection?: string;
  omitAll?: boolean;
} = {}): UseQueryResult<Option[]> => {
  const initialOptions = omitAll ? [] : [allLocationsOption];
  return useQuery({
    queryKey: ["horse_locations", collection, omitAll],
    queryFn: async ({ signal }) =>
      await get<PaginatedResponse<HorseLocation>>(
        "/horse_locations.json",
        { collection, totals: false },
        { signal },
      ).then(({ rows }) => {
        return initialOptions.concat(
          rows.map((horseLocation) => ({ label: horseLocation.name, value: horseLocation.id.toString() })),
        );
      }),
    placeholderData: initialOptions,
  });
};

type HorseLocationsIndex = PaginatedResponse<HorseLocation> & {
  totals: {
    cost_value: number;
    retail_value: number;
  };
};

export const getHorseLocations = async (queryParams: QueryParams): Promise<HorseLocationsIndex> => {
  return await get<HorseLocationsIndex>("/horse_locations.json", queryParams);
};

export const getHorseLocationOptions = async (queryParams: QueryParams): Promise<Option[]> => {
  return await get<{ id: number; name: string }[]>("/horse_locations/search_dropdown.json", queryParams).then(
    (horseLocations) => {
      return horseLocations.map((horseLocation) => ({
        value: horseLocation.id.toString(),
        label: horseLocation.name,
      }));
    },
  );
};

export const getHorseLocation = async (
  horseLocationId: number,
): Promise<{
  imageUrl: string;
  horseLocation: HorseLocation;
  linkedLocations: LinkedLocation[];
}> => {
  return await get(`/horse_locations/${horseLocationId}.json`);
};

export const getHomeRecommendations = async (queryParams: QueryParams): Promise<HomeRecommendations> => {
  return await get("/home.json", queryParams);
};

export const allLocations = "All locations";
const allLocationsOption: Option = { label: allLocations, value: allLocations };
export const allSuppliers = "All suppliers";
const allSuppliersOption: Option = { label: allLocations, value: allLocations };
export const allTypes = "All types";
const allTypesOption: Option = { label: allTypes, value: allTypes };
export const allVendors = "All vendors";
const allVendorsOption: Option = { label: allVendors, value: allVendors };

// /vendors
export const useHorseVariantVendorsOptions = ({ collection = "undiscarded", omitAll = false } = {}): UseQueryResult<
  Option[]
> => {
  const initialOptions = omitAll ? [] : [allVendorsOption];
  return useQuery({
    queryKey: ["vendors", collection, omitAll],
    queryFn: async ({ signal }) =>
      await get<{ vendors: string[] }>("/vendors.json", { collection }, { signal }).then(({ vendors }) => {
        return initialOptions.concat(vendors.map((vendor) => ({ label: vendor, value: vendor })));
      }),
    placeholderData: initialOptions,
  });
};

export const getHorseVariantsVendors = async (collection?: string): Promise<any> => {
  return await get("/vendors.json", { collection });
};

// /horse_variants
export const useHorseVariants = (
  queryParams: QueryParams,
): UseQueryResult<PaginatedResponse<IHorseVariant & { value: number }>> => {
  return useQuery({
    queryKey: ["horse_variants", queryParams],
    queryFn: async ({ signal }) => await get("/horse_variants.json", queryParams, { signal }),
    placeholderData: { rows: [], hasNext: false, hasPrev: false },
  });
};

export const useHorseVariantsProductTypeOptions = (queryParams = {}): UseQueryResult<Option[]> => {
  const initialOptions = [allTypesOption];
  return useQuery({
    queryKey: ["horse_variants/product_types", queryParams],
    queryFn: async ({ signal }) =>
      await get<{ product_types: string[] }>("/horse_variants/product_types.json", queryParams, { signal }).then(
        ({ product_types }) => {
          return initialOptions.concat(
            product_types.map((productType) => ({ label: productType, value: productType })),
          );
        },
      ),
    placeholderData: initialOptions,
  });
};

export const getHorseVariant = async (
  horseVariantId: number,
): Promise<{
  horseVariant: IHorseVariant;
  shopifyVariants: ShopifyVariant[];
  horseInventoryLevels: HorseInventoryLevel[];
  availability: {
    shopify_domain: string;
    friendly_name: string;
    available: boolean;
  }[];
}> => {
  return await get(`/horse_variants/${horseVariantId}.json`);
};

export const updateHorseVariant = async (id: number, payload: object): Promise<IHorseVariant> => {
  return await patch(`/horse_variants/${id}`, payload);
};

export const importHorseVariants = async (payload: object): Promise<any> => {
  return await post("/horse_variants/import", payload);
};

export const useHorseVariantsTotals = (
  queryParams: QueryParams,
): UseQueryResult<{
  currently_available_quantity: number;
  total_value: number;
}> => {
  return useQuery({
    queryKey: ["horse_variants/totals", queryParams],
    queryFn: async ({ signal }) => await get("/horse_variants/totals.json", queryParams, { signal }),
    placeholderData: { currently_available_quantity: 0, total_value: 0 },
  });
};

// /inventory_level_histories
export const useInventoryLevelHistories = (
  queryParams: QueryParams,
): UseQueryResult<PaginatedResponse<InventoryLevelHistory>> => {
  return useQuery({
    queryKey: ["inventory_level_histories", queryParams],
    queryFn: async ({ signal }) => await get("/inventory_level_histories.json", queryParams, { signal }),
    placeholderData: { rows: [], hasNext: false, hasPrev: false },
  });
};

// /cost_adjustments
export const getCostAdjustments = async (
  purchase_order_id: number,
  queryParams = {},
): Promise<{ cost_adjustments: CostAdjustment[] }> => {
  return await get(`/purchase_orders/${purchase_order_id}/cost_adjustments.json`, queryParams);
};

export const saveCostAdjustments = async (
  purchase_order_id: number,
  cost_adjustments: CostAdjustment[],
): Promise<{ cost_adjustments: CostAdjustment[] }> => {
  return await patch(`/purchase_orders/${purchase_order_id}/cost_adjustments`, { cost_adjustments });
};

// /purchase_orders
export const getPurchaseOrders = async (
  queryParams: QueryParams,
): Promise<
  PaginatedResponse<IndexPurchaseOrder> & {
    totals: {
      total_cost: number;
      quantity: number;
      received: number;
    };
  }
> => {
  return await get(`/purchase_orders`, queryParams);
};

export const usePurchaseOrder = (
  purchaseOrderId: number,
): UseQueryResult<{
  purchaseOrder: IPurchaseOrder;
  lineItems: IPurchaseOrderLineItem[];
}> => {
  return useQuery({
    queryKey: ["purchase_orders", purchaseOrderId],
    queryFn: async ({ signal }) => await get(`/purchase_orders/${purchaseOrderId}.json`, undefined, { signal }),
  });
};

export const createPurchaseOrder = async (payload: {
  purchase_order: CreatePurchaseOrder;
}): Promise<IPurchaseOrder> => {
  return await post("/purchase_orders.json", payload);
};

export const deletePurchaseOrder = async (id: number): Promise<any> => {
  return await deleteRequest(`/purchase_orders/${id}`);
};

export const updatePurchaseOrderSelection = async (
  purchase_order_id: number,
  payload: object,
  queryParams: QueryParams,
): Promise<any> => {
  return await patch(`/purchase_orders/${purchase_order_id}/update_selection`, payload, queryParams);
};

export const updatePurchaseOrder = async (purchase_order_id: number, payload: object): Promise<IPurchaseOrder> => {
  return await patch(`/purchase_orders/${purchase_order_id}.json`, payload);
};

export const removeAllPurchaseOrderHorseVariantLineItem = async (purchase_order_id: number): Promise<any> => {
  return await post(`/purchase_orders/${purchase_order_id}/destroy_all_line_items`);
};

export const getRecommendations = async (purchase_order_id: number, queryParams: QueryParams): Promise<any> => {
  return await get(`/purchase_orders/${purchase_order_id}/recommend_horse_variants.json`, queryParams);
};

/**
 * @param purchaseOrderId
 * @param payload
 * @param queryParams Used for preserving the sort-order when emailing a PDF
 * @returns
 */
export const emailPdf = async (
  purchaseOrderId: number,
  payload: {
    subject: string;
    to: string;
    reply_to: string;
    body: string;
  },
  queryParams: QueryParams = {},
): Promise<any> => {
  const urlSearchParams = processQueryParams(queryParams);
  return await post(`/purchase_orders/${purchaseOrderId}/email_pdf?${urlSearchParams.toString()}`, payload);
};

export const getPurchaseOrderHorseVariants = async (
  purchase_order_id: number,
  payload: QueryParams = {},
): Promise<IPurchaseOrderLineItem[]> => {
  return await get(`/purchase_orders/${purchase_order_id}/purchase_order_line_items.json`, payload);
};

export const importPurchaseOrderLineItems = async (purchaseOrderId: number, payload: object): Promise<any> => {
  return await post(`/purchase_orders/${purchaseOrderId}/purchase_order_line_items/import`, payload);
};

export const removePurchaseOrderHorseVariantLineItem = async (
  purchase_order_horse_variant_id: number,
): Promise<any> => {
  return await deleteRequest(`/purchase_order_line_items/${purchase_order_horse_variant_id}`);
};

// /read_banners
export const getReadBanners = async (): Promise<any> => {
  return await get("/read_banners.json");
};

export const getReadBanner = async (banner_name: string): Promise<any> => {
  return await get(`/read_banners/${banner_name}.json`);
};

export const createReadBanner = async (banner_name: string): Promise<any> => {
  return await post("/read_banners.json", { read_banners: { banner_name } });
};

export function forceNumber(value: number | string | undefined): number | undefined {
  if (value === undefined) {
    return undefined;
  }
  return typeof value === "string" ? parseFloat(value) : value;
}

// /sales_trends
export const useSalesTrends = (
  queryParams: SalesTrendsQueryParams,
): UseQueryResult<PaginatedResponse<IHorseVariant>> => {
  return useQuery({
    queryKey: ["sales_trends", queryParams],
    queryFn: async ({ signal }) => await get("/sales_trends.json", queryParams, { signal }),
  });
};

export const useTotalsAndMax = (queryParams: SalesTrendsQueryParams): UseQueryResult<SalesTrendsTotalsAndMax> => {
  return useQuery({
    queryKey: ["sales_trends/totals_and_max", queryParams],
    queryFn: async ({ signal }) => await get("/sales_trends/totals_and_max.json", queryParams, { signal }),
    placeholderData: {
      total_sales_pre_day: 0,
      total_sales: 0,
      max_sales_rate: 1.9,
      total_ordered: 0,
      total_available: 0,
    },
  });
};

// shopify_shops
export const useShopifyShop = (): UseQueryResult<{ name: string; email: string }> => {
  return useQuery({
    queryKey: ["shopify_shop"],
    queryFn: async ({ signal }) => await get("/shopify_shop.json", undefined, { signal }),
    placeholderData: { name: "", email: "" },
  });
};

// currencies
export const useCurrencies = (): UseQueryResult<string[]> => {
  return useQuery({
    queryKey: ["currencies"],
    queryFn: async ({ signal }) => await get<string[]>("/currencies.json", undefined, { signal }),
    placeholderData: [],
  });
};

// /shopify_channels
export const useShopifyChannelAvailabilityOptions = (): UseQueryResult<FormattedShopifyChannel[]> => {
  return useQuery({
    queryKey: ["shopify_channels"],
    queryFn: async ({ signal }) =>
      await get<{ shopify_channels: { id: number; friendly_name: string }[] }>("/shopify_channels.json", undefined, {
        signal,
      }).then(({ shopify_channels }) => {
        return formatShopifyChannels(shopify_channels);
      }),
    placeholderData: [],
  });
};

// Format friendly_shopify_channels from application_helper.rb
// Horse specific
function formatShopifyChannels(shopifyChannels: { id: number; friendly_name: string }[]): FormattedShopifyChannel[] {
  return shopifyChannels.flatMap((shopifyChannel) => {
    const [channelName, storeName] = shopifyChannel.friendly_name.split("-");
    const available = `Available on ${channelName}`;
    const unAvailable = `Unavailable on ${channelName}`;
    const availableKey = `Available on ${shopifyChannel.friendly_name}`;
    const unAvailableKey = `Unavailable on ${shopifyChannel.friendly_name}`;
    return [
      {
        helpText: storeName,
        key: availableKey,
        label: available,
        type: "available",
        value: `${shopifyChannel.id}:true`,
        shopifyChannelId: shopifyChannel.id,
      },
      {
        helpText: storeName,
        key: unAvailableKey,
        label: unAvailable,
        type: "unavailable",
        value: `${shopifyChannel.id}:false`,
        shopifyChannelId: shopifyChannel.id,
      },
    ];
  });
}

export const useShopifyChannelSalesOptions = (): UseQueryResult<Option[]> => {
  return useQuery({
    queryKey: ["shopify_channels"],
    queryFn: async ({ signal }) =>
      await get("/shopify_channels.json", undefined, { signal }).then(
        ({
          shopify_channels,
        }: {
          shopify_channels: {
            id: number;
            friendly_name: string;
          }[];
        }): Option[] => {
          return shopify_channels.map((shopifyChannel) => {
            return { label: shopifyChannel.friendly_name, value: shopifyChannel.id.toString() };
          });
        },
      ),
    placeholderData: [],
  });
};

export const useBanners = (): UseQueryResult<Banner> => {
  return useQuery({
    queryKey: ["banners"],
    queryFn: async ({ signal }) => await get(`/banners.json`, undefined, { signal }),
    placeholderData: {},
  });
};

export const getSuppliers = async (
  queryParams: QueryParams,
): Promise<PaginatedResponse<{ id: number; name: string }>> => {
  return await get("/suppliers.json", queryParams);
};

export const useSuppliersSearchOptions = ({ omitAll = false } = {}): UseQueryResult<Option[]> => {
  const initialOptions = omitAll ? [] : [allSuppliersOption];
  return useQuery({
    queryKey: ["suppliers", omitAll],
    queryFn: async ({ signal }) =>
      await get<ISupplierSearchOption[]>(`/suppliers/search_dropdown.json`, undefined, { signal }).then(
        (supplierOptions) => {
          return initialOptions.concat(supplierOptions.map((sup) => ({ label: sup.name, value: sup.id.toString() })));
        },
      ),
    placeholderData: initialOptions,
  });
};

export const getSupplier = async (
  supplierId: number,
): Promise<{
  supplier: Supplier;
  supplierHorseVariants: SupplierHorseVariant[];
  currencies: string[];
}> => {
  return await get(`/suppliers/${supplierId}.json`);
};

export const createSupplier = async (payload: object): Promise<Supplier> => {
  return await post("/suppliers.json", payload);
};

export const deleteSupplier = async (supplierId: number): Promise<any> => {
  return await deleteRequest(`/suppliers/${supplierId}`);
};

export const updateSupplierSelection = async (
  supplierId: number,
  payload: object,
  queryParams: QueryParams,
): Promise<any> => {
  return await patch(`/suppliers/${supplierId}/update_selection`, payload, queryParams);
};

export const updateSupplier = async (supplierId: number, payload: object): Promise<{ supplier: Supplier }> => {
  return await patch(`/suppliers/${supplierId}.json`, payload);
};

export const importSupplierHorseVariants = async (supplierId: number, payload: object): Promise<any> => {
  return await post(`/suppliers/${supplierId}/supplier_horse_variants/import`, payload);
};

export const getSupplierHorseVariants = async (
  supplierId: number,
  queryParams: QueryParams,
): Promise<PaginatedResponse<SupplierHorseVariant>> => {
  return await get(`/suppliers/${supplierId}/supplier_horse_variants.json`, queryParams);
};

export const removeAllSupplierHorseVariants = async (supplierId: number): Promise<any> => {
  return await post(`/suppliers/${supplierId}/destroy_all_line_items`);
};

// /supplier_horse_variants
export const removeSupplierHorseVariant = async (supplierHorseVariantId: number): Promise<any> => {
  return await deleteRequest(`/supplier_horse_variants/${supplierHorseVariantId}`);
};

// /tags
export const getAllHorseVariantsTagOptions = async (search?: string): Promise<string[]> => {
  return await get("/tags.json", { search });
};

// /transfer_orders
export const useTransferOrders = (
  queryParams: QueryParams,
): UseQueryResult<PaginatedResponse<IndexTransferOrder> & { total_quantity_sent: number; total_value: number }> => {
  return useQuery({
    queryKey: ["transfer_orders", queryParams],
    queryFn: async ({ signal }) => await get(`/transfer_orders.json`, queryParams, { signal }),
    placeholderData: { rows: [], hasNext: false, hasPrev: false, total_quantity_sent: 0, total_value: 0 },
  });
};

export const useTransferOrder = (
  transferOrderId: number,
): UseQueryResult<{
  origin: HorseLocation;
  destination: HorseLocation;
  transferOrder: TransferOrder;
  lineItems?: TransferOrderLineItem[];
}> => {
  return useQuery({
    queryKey: ["transfer_order", transferOrderId],
    queryFn: async ({ signal }) => await get(`/transfer_orders/${transferOrderId}.json`, undefined, { signal }),
    placeholderData: { origin: undefined, destination: undefined, transferOrder: undefined, lineItems: undefined },
  });
};

export const deleteTransferOrder = async (id: number): Promise<any> => {
  return await deleteRequest(`/transfer_orders/${id}`);
};

export const updateTransferOrderSelection = async (
  transfer_order_id: number,
  payload: object,
  queryParams: QueryParams,
): Promise<any> => {
  return await patch(`/transfer_orders/${transfer_order_id}/update_selection`, payload, queryParams);
};

export const updateTransferOrder = async (transfer_order_id: number, payload: object): Promise<TransferOrder> => {
  return await patch(`/transfer_orders/${transfer_order_id}.json`, payload);
};

export const createTransferOrder = async (payload: object): Promise<TransferOrder> => {
  return await post("/transfer_orders.json", payload);
};

export const removeAllTransferOrderHorseVariantLineItem = async (transfer_order_id: number): Promise<any> => {
  return await post(`/transfer_orders/${transfer_order_id}/destroy_all_line_items`);
};

export const importTransferOrderLineItems = async (transferOrderId: number, payload: object): Promise<any> => {
  return await post(`/transfer_orders/${transferOrderId}/transfer_order_horse_variants/import`, payload);
};

export const getTransferOrderHorseVariants = async (transfer_order_id: number): Promise<TransferOrderLineItem[]> => {
  return await get(`/transfer_orders/${transfer_order_id}/transfer_order_horse_variants.json`);
};

// /transfer_order_horse_variants
export const removeTransferOrderHorseVariantLineItem = async (
  transfer_order_horse_variant_id: number,
): Promise<any> => {
  return await deleteRequest(`/transfer_order_horse_variants/${transfer_order_horse_variant_id}`);
};

export const useUser = (): UseQueryResult<User> => {
  return useQuery({
    queryKey: ["user"],
    queryFn: async ({ signal }) => await get(`/user.json`, undefined, { signal }),
    placeholderData: { user: { currency: "USD", syncbackSetting: undefined } },
  });
};

export const updateUser = async (currentUserId: number, payload: UpdateUser): Promise<{ user: User }> => {
  return await patch(`/users/${currentUserId}`, { user: payload });
};

export const uploadLogo = async (
  currentUserId: number,
  payload: FormData,
): Promise<{
  brandLogo: string;
}> => {
  return await post(`/users/${currentUserId}/upload_logo`, payload);
};

export const saveCombineStore = async (
  payload: object,
): Promise<{
  id: number;
  shopify_shop_id: number;
  add_store: string;
}> => {
  return await post("/combine_stores", payload);
};

// bundles
export const getBundles = async (queryParams: QueryParams): Promise<PaginatedResponse<Bundle>> => {
  return await get(`/bundles.json`, queryParams);
};

export const createBundle = async (payload: object): Promise<Bundle> => {
  return await post("/bundles.json", payload);
};

export const deleteBundle = async (bundleId: number): Promise<void> => {
  await deleteRequest(`/bundles/${bundleId}`);
};

export const getBundle = async (
  bundleId: number,
): Promise<{ bundle: Bundle; bundleHorseVariants: ChildBundleHorseVariant[] }> => {
  return await get(`/bundles/${bundleId}.json`);
};

export const updateBundle = async (bundleId: number, payload: object): Promise<Bundle> => {
  return await patch(`/bundles/${bundleId}.json`, payload);
};

export const updateBundleSelection = async (
  bundleId: number,
  payload: object,
  queryParams: QueryParams,
): Promise<{ horse_variant_ids: number[]; errors?: string[] }> => {
  return await patch(`/bundles/${bundleId}/update_selection`, payload, queryParams);
};

export const getBundleHorseVariants = async (bundleId: number): Promise<ChildBundleHorseVariant[]> => {
  return await get(`/bundles/${bundleId}/bundle_horse_variants.json`);
};

export const removeBundleHorseVariant = async (bundleHorseVariantId: number): Promise<{ id: number }> => {
  return await deleteRequest(`/bundle_horse_variants/${bundleHorseVariantId}`);
};

export const removeAllBundleHorseVariants = async (bundleId: number): Promise<{ horse_variant_ids: number[] }> => {
  return await post(`/suppliers/${bundleId}/destroy_all_line_items`);
};

export const useSettings = (): UseQueryResult<{
  currentUser: User;
  currencies: string[];
  shopifyShops: ShopifyShop[];
}> => {
  return useQuery({
    queryKey: ["/settings"],
    queryFn: async ({ signal }) => await get("/settings", undefined, { signal }),
    placeholderData: { currentUser: undefined, currencies: undefined, shopify_shops: undefined },
  });
};

const patch = async <ReturnType>(path: string, payload: object, queryParams = {}): Promise<ReturnType> => {
  await window.initialSessionTokenPromise;
  const urlSearchParams = processQueryParams(queryParams);
  const request = new Request(`${path}?${urlSearchParams.toString()}`, {
    method: "PATCH",
    headers: {
      Authorization: `Bearer ${window.sessionToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
    redirect: "error",
  });
  let response: Response;
  try {
    response = await fetch(request);
  } catch (error: unknown) {
    if (shouldReportError(error)) {
      Rollbar.error(error || new Error("PATCH error"), request);
    }
    throw new Error(`Failed to update ${path}`);
  }

  return handleResponse<ReturnType>(response);
};

const get = async <T>(
  path: string,
  queryParams: QueryParams = {},
  { signal }: { signal?: AbortSignal } = {},
): Promise<T> => {
  await window.initialSessionTokenPromise;
  const urlSearchParams = processQueryParams(queryParams);
  const request = new Request(`${path}?${urlSearchParams.toString()}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${window.sessionToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    redirect: "error",
  });
  let response: Response;
  try {
    response = await fetch(request, { signal });
  } catch (error: unknown) {
    if (shouldReportError(error)) {
      Rollbar.error(error || new Error("GET error"), request);
    }
    throw new Error(`Failed to find ${path}`);
  }
  return handleResponse<T>(response);
};

const post = async <T>(path: string, payload = {}): Promise<T> => {
  await window.initialSessionTokenPromise;
  const headers: HeadersInit = {
    Authorization: `Bearer ${window.sessionToken}`,
  };

  if (!(payload instanceof FormData)) {
    headers.Accept = "application/json";
    headers["Content-Type"] = "application/json";
  }

  const request = new Request(path, {
    method: "POST",
    headers,
    body: payload instanceof FormData ? payload : JSON.stringify(payload),
    redirect: "error",
  });
  let response: Response;
  try {
    response = await fetch(request);
  } catch (error: unknown) {
    if (shouldReportError(error)) {
      Rollbar.error(error || new Error("POST error"), request);
    }
    throw new Error(`Failed to create ${path}`);
  }
  return handleResponse<T>(response);
};

const deleteRequest = async <T>(path: string): Promise<T> => {
  await window.initialSessionTokenPromise;
  const request = new Request(path, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${window.sessionToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    redirect: "error",
  });
  let response: Response;
  try {
    response = await fetch(request);
  } catch (error: unknown) {
    if (shouldReportError(error)) {
      Rollbar.error(error || new Error("DELETE error"), request);
    }
    throw new Error(`Failed to delete ${path}`);
  }

  return handleResponse<T>(response);
};

export interface ErrorResponse {
  errors: string[];
}

const handleResponse = async <T>(response: Response): Promise<T | undefined> => {
  if (response.status < 300) {
    try {
      return (await response.json()) as Promise<T>;
    } catch (error: unknown) {
      if (shouldReportError(error)) {
        Rollbar.error(error || new Error("JSON parse error"), response);
      }
      return undefined;
    }
  } else {
    let jsonResponse: { errors: string[] } | undefined;
    try {
      jsonResponse = await response.json();
    } catch (error: unknown) {
      if (shouldReportError(error)) {
        Rollbar.error(error || new Error("JSON parse error"), response);
      }
      throw new Error(`Failed to parse response: ${response.statusText} (${response.status}) from ${response.url}`);
    }
    if (jsonResponse.errors && Object.keys(jsonResponse.errors).length > 0) {
      jsonResponse.errors.forEach((errorMessage) => {
        Rollbar.error(errorMessage, response);
      });
      // eslint-disable-next-line @typescript-eslint/only-throw-error
      throw jsonResponse;
    } else {
      throw new Error(`Failed to parse response: ${response.statusText} (${response.status}) from ${response.url}`);
    }
  }
};

export const processQueryParams = (queryParams: QueryParams): URLSearchParams => {
  const formattedQueryParams = { ...queryParams };
  const urlSearchParams = new URLSearchParams();
  Object.keys(formattedQueryParams).forEach((key: string) => {
    if (Array.isArray(formattedQueryParams[key])) {
      const values = formattedQueryParams[key] as (Date | boolean | number | string)[];

      if (!key.endsWith("[]")) {
        delete formattedQueryParams[key];
        key = `${key}[]`;
      }

      if (values.length > 0) {
        values.forEach((value) => {
          conditionallyAppend(urlSearchParams, key, value);
        });
      }
    } else if (
      typeof formattedQueryParams[key] === "object" &&
      !(formattedQueryParams[key] instanceof Date) &&
      formattedQueryParams[key] !== null &&
      formattedQueryParams[key] !== undefined &&
      formattedQueryParams[key] !== ""
    ) {
      const values = formattedQueryParams[key] as NumberRangeFilter | { column: string; direction: string };
      delete formattedQueryParams[key];
      Object.keys(values).forEach((subKey) => {
        const subValue = values[subKey] as Date | boolean | number | string;
        conditionallyAppend(urlSearchParams, `${key}[${subKey}]`, subValue);
      });
    } else if (
      formattedQueryParams[key] !== null &&
      formattedQueryParams[key] !== undefined &&
      formattedQueryParams[key] !== ""
    ) {
      conditionallyAppend(urlSearchParams, key, formattedQueryParams[key] as Date | boolean | number | string);
    }
  });
  return urlSearchParams;
};

function conditionallyAppend(
  urlSearchParams: URLSearchParams,
  key: string,
  value: Date | boolean | number | string,
): void {
  if (value !== null && value !== undefined && value !== "") {
    if (value instanceof Date && isNaN(value.getTime())) {
      return;
    }
    urlSearchParams.append(key, formatValue(value));
  }
}

function formatValue(value: Date | boolean | number | string): string {
  if (typeof value === "boolean") {
    return value ? "true" : "false";
  } else if (value instanceof Date) {
    return value.toISOString();
  }
  return value.toString();
}

// HACK: This hack is caused by the need to pass JWT tokens in the HEADERS
// That means we can't open tabs
// So the only solution is to `fetch` the file in the background and the give it to the browser as a blob
// https://stackoverflow.com/questions/29452031/how-to-handle-file-downloads-with-jwt-based-authentication
export const downloadFile = async (filePath: string, filename: string, queryParams?: QueryParams): Promise<void> => {
  const urlSearchParams = processQueryParams(queryParams);

  const anchor = document.createElement("a");
  document.body.appendChild(anchor);
  const headers = new Headers();
  await window.initialSessionTokenPromise;
  headers.append("Authorization", `Bearer ${window.sessionToken}`);

  const path = queryParams ? `${filePath}?${urlSearchParams.toString()}` : filePath;
  const response = await fetch(path, { headers });
  if (response.status >= 300) {
    throw new Error(`Failed to download file: ${response.statusText}`);
  }
  const blob = await response.blob();
  const contentDisposition = response.headers.get("content-disposition") || "";
  let serverFilename = contentDisposition.split("; ").find((property) => property.startsWith("filename"));
  if (serverFilename !== undefined) {
    serverFilename = serverFilename.split("=")[1].replaceAll('"', "");
  }
  const objectUrl = window.URL.createObjectURL(blob);

  anchor.href = objectUrl;
  anchor.download = serverFilename || filename;
  anchor.click();

  window.URL.revokeObjectURL(objectUrl);
};

function shouldReportError(error: unknown): boolean {
  if (error instanceof Error) {
    return (
      !error.message.includes("The user aborted a request") &&
      !error.message.includes("signal is aborted without reason") &&
      !error.message.includes("Fetch is aborted") &&
      !error.message.includes("NetworkError when attempting to fetch resource") &&
      !error.message.includes(`Unexpected token '<', "<!DOCTYPE "... is not valid JSON`) &&
      !error.message.includes("The operation was aborted.") &&
      !error.message.includes("Failed to fetch")
    );
  } else {
    return true;
  }
}
