import {
  Button,
  Icon,
  Label,
  Select,
  Tooltip,
  Badge,
  LegacyStack,
  TextField,
  FormLayout,
  TextContainer,
} from "@shopify/polaris";
import { MobileCancelMajor } from "@shopify/polaris-icons";
import React, { useCallback, useEffect, useState } from "react";
import { formatMoney, assertNumber, getCurrencySymbol, extractMessageFromError } from "../../../helper_functions/utils";
import { getCostAdjustments, saveCostAdjustments } from "../../../api_utils/requests";
import type { CostAdjustment, CostAdjustmentEditable } from "../../../api_utils/types";

const COST_ITEMS = [
  "Custom duties",
  "Discount",
  "Foreign transaction fee",
  "Freight fee",
  "Insurance",
  "Rush fee",
  "Surcharge",
  "Shipping",
  "Other",
];

const findTaxCostAdjustment = (costAdjustments: CostAdjustmentEditable[]): CostAdjustmentEditable | undefined =>
  costAdjustments.find((costAdj) => costAdj.label === "Tax");

// Make discounts negative
const formatAmount = (costAdjustment: CostAdjustmentEditable): number => {
  const amountNumber = assertNumber(costAdjustment.amount);
  return costAdjustment.label === "Discount" ? -Math.abs(amountNumber) : amountNumber;
};

const formatClientSide = (costAdjustments: CostAdjustment[]): CostAdjustmentEditable[] =>
  costAdjustments.map((costAdjustment) => ({
    ...costAdjustment,
    amount: formatMoney(costAdjustment.amount),
  }));

const sortCostAdjustments = (costAdjustments: CostAdjustmentEditable[]): CostAdjustmentEditable[] =>
  costAdjustments.sort((a, b) => {
    if (!a.id) {
      return 1;
    } else if (!b.id) {
      return -1;
    } else if (a.id < b.id) {
      return -1;
    } else if (a.id > b.id) {
      return 1;
    } else {
      return 0;
    }
  });

function Summary({
  currency,
  lineTotal = 0,
  purchaseOrder,
  setErrorMessage,
}: {
  readonly currency: string;
  readonly lineTotal: number;
  readonly purchaseOrder: { id: number; supplier: { minimum_order_value: number } };
  readonly setErrorMessage: (message: string) => void;
}): React.JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [costAdjustments, setCostAdjustments] = useState<CostAdjustmentEditable[]>([]);
  const [minimumOrderValueWarning, setMinimumOrderValueWarning] = useState(false);

  useEffect(() => {
    async function fetchData(): Promise<void> {
      const { cost_adjustments } = await getCostAdjustments(purchaseOrder.id);
      setCostAdjustments(formatClientSide(cost_adjustments));
    }
    void fetchData();
  }, [purchaseOrder.id]);

  const handleSaveCostAdjustments = useCallback(
    (unsavedCostAdjustments: CostAdjustmentEditable[]): void => {
      setIsLoading(true);
      const payload = unsavedCostAdjustments.map(
        (costAdjustment: CostAdjustmentEditable): CostAdjustment => ({
          ...costAdjustment,
          purchase_order_id: purchaseOrder.id,
          amount: formatAmount(costAdjustment),
        }),
      );

      saveCostAdjustments(purchaseOrder.id, payload)
        .then(({ cost_adjustments }) => {
          setCostAdjustments(formatClientSide(cost_adjustments));
        })
        .catch((err: unknown): void => {
          Rollbar.error(err, payload);
          const message = extractMessageFromError(err);
          setErrorMessage(message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [purchaseOrder.id, setErrorMessage],
  );

  const removeCostAdjustment = (costAdjustmentId: number) => (): void => {
    let costAdjustment = costAdjustments.find((costAdj) => costAdj.id === costAdjustmentId);
    costAdjustment = {
      ...costAdjustment,
      _destroy: true,
    };
    const clonedCostAdjustments = [...costAdjustments.filter((item) => item.id !== costAdjustmentId), costAdjustment];
    handleSaveCostAdjustments(clonedCostAdjustments);
  };

  const handleCostLabelChange =
    (costAdjustmentId: number) =>
    (label: string): void => {
      let costAdjustment = costAdjustments.find((costAdj) => costAdj.id === costAdjustmentId);
      costAdjustment = {
        ...costAdjustment,
        label,
      };
      const clonedCostAdjustments = [...costAdjustments.filter((item) => item.id !== costAdjustmentId), costAdjustment];
      handleSaveCostAdjustments(clonedCostAdjustments);
    };

  // NO SAVE
  const handleCostAmountChange =
    (costAdjustmentId: number) =>
    (amount: string): void => {
      let costAdjustment = costAdjustments.find((costAdj) => costAdj.id === costAdjustmentId);
      costAdjustment = {
        ...costAdjustment,
        amount,
      };
      const clonedCostAdjustments = [...costAdjustments.filter((item) => item.id !== costAdjustmentId), costAdjustment];
      setCostAdjustments(sortCostAdjustments(clonedCostAdjustments));
    };

  // NO SAVE
  const taxChange = useCallback(
    (costAdjustmentId: number, newAmount: string): void => {
      let clonedCostAdjustments: CostAdjustmentEditable[] = [];
      if (costAdjustmentId) {
        let costAdjustment = costAdjustments.find((costAdj) => costAdj.id === costAdjustmentId);
        costAdjustment = {
          ...costAdjustment,
          amount: newAmount,
        };
        clonedCostAdjustments = [...costAdjustments.filter((item) => item.id !== costAdjustmentId), costAdjustment];
      } else {
        const notTax = costAdjustments.filter((item) => item.label !== "Tax");
        clonedCostAdjustments = [...notTax, { label: "Tax", amount: newAmount } as CostAdjustmentEditable];
      }
      setCostAdjustments(sortCostAdjustments(clonedCostAdjustments));
    },
    [costAdjustments],
  );

  const handleAddAdjustmentClick = useCallback((): void => {
    const clonedCostAdjustments: CostAdjustmentEditable[] = [
      ...costAdjustments,
      { label: "Other", purchase_order_id: purchaseOrder.id } as CostAdjustmentEditable,
    ];
    handleSaveCostAdjustments(clonedCostAdjustments);
  }, [handleSaveCostAdjustments, costAdjustments, purchaseOrder.id]);

  const taxCostAdjustment = findTaxCostAdjustment(costAdjustments);
  const subTotal = assertNumber(lineTotal) + assertNumber(taxCostAdjustment?.amount);

  const costAdjTotal = costAdjustments.reduce((acc, costAdj) => acc + assertNumber(costAdj.amount), 0);
  const total = costAdjTotal + assertNumber(lineTotal);

  useEffect(() => {
    if (total > 0 && total < purchaseOrder?.supplier?.minimum_order_value) {
      setMinimumOrderValueWarning(true);
    } else {
      setMinimumOrderValueWarning(false);
    }
  }, [purchaseOrder, total]);

  const notTaxAndNotDestroyed = costAdjustments.filter((item) => item.label !== "Tax" && !item._destroy);

  const save = useCallback(() => {
    handleSaveCostAdjustments(costAdjustments);
  }, [handleSaveCostAdjustments, costAdjustments]);

  const changeTaxAmount = useCallback(
    (newAmount: string) => {
      taxChange(taxCostAdjustment?.id, newAmount);
    },
    [taxCostAdjustment?.id, taxChange],
  );

  return (
    <FormLayout>
      <FormLayout.Group>
        <LegacyStack>
          <LegacyStack.Item fill>
            <Label id="taxesHeading">Taxes</Label>
          </LegacyStack.Item>
          <LegacyStack.Item>
            <div className="w120">
              <TextField
                align="right"
                autoComplete="off"
                id="tax"
                label=""
                labelHidden
                onBlur={save}
                onChange={changeTaxAmount}
                placeholder="0.00"
                prefix={getCurrencySymbol(currency)}
                readOnly={isLoading}
                type="currency"
                value={taxCostAdjustment?.amount?.toString()}
              />
            </div>
          </LegacyStack.Item>
          <LegacyStack.Item>
            <div style={{ width: "22px" }} />
          </LegacyStack.Item>
        </LegacyStack>
      </FormLayout.Group>
      <FormLayout.Group>
        <LegacyStack>
          <LegacyStack.Item fill>
            <Label id="subtotalHeading">Subtotal</Label>
          </LegacyStack.Item>
          <LegacyStack>
            <LegacyStack.Item>
              <TextContainer>{formatMoney(subTotal, currency, 4)}</TextContainer>
            </LegacyStack.Item>
            <LegacyStack.Item>
              <div style={{ width: "32px" }} />
            </LegacyStack.Item>
          </LegacyStack>
        </LegacyStack>
      </FormLayout.Group>
      {notTaxAndNotDestroyed.map((costAdjustment) => (
        <FormLayout.Group key={costAdjustment.id}>
          <LegacyStack alignment="center" distribution="leading" spacing="tight">
            <LegacyStack.Item>
              <Select
                disabled={isLoading}
                id="description"
                label=""
                labelHidden
                onChange={handleCostLabelChange(costAdjustment.id)}
                options={COST_ITEMS.map((item) => ({ label: item, value: item }))}
                value={costAdjustment.label}
              />
            </LegacyStack.Item>
            <LegacyStack.Item fill />
            <LegacyStack alignment="center" wrap={false}>
              <LegacyStack.Item>
                <div className="w120">
                  <TextField
                    align="right"
                    autoComplete="off"
                    id="monetaryValue"
                    inputMode="numeric"
                    label=""
                    labelHidden
                    onBlur={save}
                    onChange={handleCostAmountChange(costAdjustment.id)}
                    placeholder="0.00"
                    prefix={getCurrencySymbol(currency)}
                    readOnly={isLoading}
                    type="currency"
                    value={costAdjustment.amount?.toString()}
                  />
                </div>
              </LegacyStack.Item>
              <LegacyStack.Item>
                <Button
                  icon={<Icon source={MobileCancelMajor} />}
                  key="3"
                  onClick={removeCostAdjustment(costAdjustment.id)}
                  variant="plain"
                />
              </LegacyStack.Item>
            </LegacyStack>
          </LegacyStack>
        </FormLayout.Group>
      ))}
      <Button onClick={handleAddAdjustmentClick} variant="plain">
        Add adjustment
      </Button>
      <LegacyStack alignment="center" distribution="leading">
        <LegacyStack.Item>
          <Label id="totalHeading">Total</Label>
        </LegacyStack.Item>
        <LegacyStack.Item fill />
        <LegacyStack.Item>
          <TextContainer>{formatMoney(total, currency, 4)}</TextContainer>
        </LegacyStack.Item>
        <LegacyStack.Item>
          <div style={{ width: "32px" }} />
        </LegacyStack.Item>
      </LegacyStack>
      {minimumOrderValueWarning ? (
        <Tooltip content="Total is less than minimum order value">
          <Badge tone="attention">Total bill is less than minimum order value</Badge>
        </Tooltip>
      ) : null}
    </FormLayout>
  );
}

export default Summary;
