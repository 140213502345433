import React, { useState, useCallback, useEffect, useMemo } from "react";
import { Page, LegacyCard, IndexTable, LegacyStack, Pagination, Button, EmptyState } from "@shopify/polaris";
import qs from "query-string";
import { commaDelimiter, formatMoney, assertNumber, softAssertNumber } from "../../../helper_functions/utils";
import { PurchaseOrdersSearchFieldAndMoreFilters } from "./PurchaseOrdersSearchFieldAndMoreFilters";
import { getPurchaseOrders, useUser } from "../../../api_utils/requests";
import { formatDateStringToMMDYY } from "../../common/CustomDatePicker/utils";
import { Footer } from "../../common/Footer";
import { PurchaseOrdersIndexPageSkeleton } from "../../common/skeletons";
import type { IndexPurchaseOrder, QueryParams } from "../../../api_utils/types";
import type { NonEmptyArray } from "@shopify/polaris/build/ts/src/types";
import type { IndexTableHeading } from "@shopify/polaris/build/ts/src/components/IndexTable";
import { styledDivElement } from "../../../helper_functions/component_utils";

const columnNames = [
  { title: "Label" },
  { title: "Vendor/Supplier" },
  { title: "Destination" },
  { title: "Status" },
  { title: "Date ordered" },
  { title: "Arrival date" },
  { title: "Requested" },
  { title: "Received" },
  { title: "Cost" },
] satisfies NonEmptyArray<IndexTableHeading>;
const TABS = {
  ALL: 0,
  DRAFT: 1,
  ORDERED: 2,
  CLOSED: 3,
};

const COLLECTIONS = {
  [TABS.ALL]: "all",
  [TABS.DRAFT]: "draft",
  [TABS.ORDERED]: "ordered",
  [TABS.CLOSED]: "closed",
};

const statusColor = (status: string): string => {
  if (status === "Received") {
    return "rgba(174, 233, 209, 1)";
  } else if (status === "Ordered") {
    return "rgba(164, 232, 242, 1)";
  } else {
    return "rgba(228, 229, 231, 1)";
  }
};

const purchaseOrderHelpPageUrl =
  "https://horse-inventory.notion.site/Purchase-orders-directory-20399ba15a2443058d30aa5f8a2ec415";

function PurchaseOrdersIndex(): React.ReactElement {
  const [purchaseOrders, setPurchaseOrders] = useState<IndexPurchaseOrder[]>([]);
  const [totals, setTotals] = useState<{
    total_cost: number;
    quantity: number;
    received: number;
  }>({
    total_cost: 0,
    quantity: 0,
    received: 0,
  });
  const [hasNext, setHasNext] = useState(false);
  const [hasPrev, setHasPrev] = useState(false);

  const parsedUrlSearch = qs.parse(window.location.search);
  const [filters, setFilters] = useState<QueryParams>(parsedUrlSearch);
  const [pageNum, setPageNum] = useState(softAssertNumber(parsedUrlSearch.page as string) || 1);
  const [collection, setCollection] = useState<number>(
    softAssertNumber(parsedUrlSearch.collection as string) || TABS.ALL,
  );
  const [isLoading, setIsLoading] = useState(true);
  const {
    data: {
      user: { currency: userCurrency },
    },
    isLoading: pageLoading,
  } = useUser();

  useEffect(() => {
    setIsLoading(true);
    getPurchaseOrders({
      ...filters,
      by_state: COLLECTIONS[collection] === COLLECTIONS[TABS.ALL] ? undefined : COLLECTIONS[collection],
      page: pageNum,
    }).then((newPurchaseOrders) => {
      setPurchaseOrders(newPurchaseOrders.rows);
      setHasNext(newPurchaseOrders.hasNext);
      setHasPrev(newPurchaseOrders.hasPrev);
      setTotals(newPurchaseOrders.totals);
      setIsLoading(false);
    });
  }, [filters, collection, pageNum]);

  const setPageNumAndScroll = useCallback((newPageNum: number) => {
    setPageNum(newPageNum);
    document.getElementsByClassName("Polaris-IndexTable__Table")[0]?.scrollIntoView();
  }, []);
  const nextPage = useCallback(() => {
    if (hasNext) {
      setPageNumAndScroll(pageNum + 1);
    }
  }, [hasNext, pageNum, setPageNumAndScroll]);
  const prevPage = useCallback(() => {
    if (hasPrev) {
      setPageNumAndScroll(pageNum - 1);
    }
  }, [hasPrev, pageNum, setPageNumAndScroll]);

  const emptyState = useMemo(
    () => (
      <EmptyState
        action={{
          content: "Create purchase order",
          url: "/purchase_orders/new",
        }}
        heading="No purchase orders found"
        image=""
      />
    ),
    [],
  );

  const setFiltersResetPageNum = useCallback((newFilters: QueryParams) => {
    setFilters(newFilters);
    setPageNum(1);
  }, []);

  const setCollectionResetPageNum = useCallback((newCollection: number) => {
    setCollection(newCollection);
    setPageNum(1);
  }, []);

  const pageMarkup = (
    <Page
      fullWidth
      primaryAction={{
        content: "Create purchase order",
        url: "/purchase_orders/new",
      }}
      title="Purchase orders"
    >
      <LegacyCard>
        <PurchaseOrdersSearchFieldAndMoreFilters
          onSelect={setCollectionResetPageNum}
          selected={collection}
          setFilters={setFiltersResetPageNum}
        />
        <IndexTable
          emptyState={emptyState}
          headings={columnNames}
          itemCount={purchaseOrders.length}
          loading={isLoading}
          selectable={false}
        >
          <IndexTable.Row id="purchaseOrdersIndexTotalsRow" key={0} position={0} rowType="subheader">
            <IndexTable.Cell>Totals</IndexTable.Cell>
            <IndexTable.Cell />
            <IndexTable.Cell />
            <IndexTable.Cell />
            <IndexTable.Cell />
            <IndexTable.Cell />
            <IndexTable.Cell>{commaDelimiter(totals.quantity)}</IndexTable.Cell>
            <IndexTable.Cell>{commaDelimiter(totals.received)}</IndexTable.Cell>
            <IndexTable.Cell>{formatMoney(totals.total_cost, userCurrency)}</IndexTable.Cell>
          </IndexTable.Row>
          {purchaseOrders.map((purchaseOrder, index) => (
            <IndexTable.Row id={purchaseOrder.id.toString()} key={purchaseOrder.id} position={index + 1}>
              <IndexTable.Cell>
                <Button url={`/purchase_orders/${purchaseOrder.id}`} variant="plain">
                  {purchaseOrder.label || purchaseOrder.id.toString()}
                </Button>
              </IndexTable.Cell>
              <IndexTable.Cell>
                <span className="truncate" style={{ maxWidth: "250px" }}>
                  {purchaseOrder.vendor || purchaseOrder.supplierName}
                </span>
              </IndexTable.Cell>
              <IndexTable.Cell>{purchaseOrder.horse_location_name}</IndexTable.Cell>
              <IndexTable.Cell>
                {styledDivElement(statusColor(purchaseOrder.status), purchaseOrder.status)}
              </IndexTable.Cell>
              <IndexTable.Cell>{formatDateStringToMMDYY(purchaseOrder.ordered_date)}</IndexTable.Cell>
              <IndexTable.Cell>{formatDateStringToMMDYY(purchaseOrder.date_received)}</IndexTable.Cell>
              <IndexTable.Cell>{assertNumber(purchaseOrder.quantity)}</IndexTable.Cell>
              <IndexTable.Cell>{assertNumber(purchaseOrder.received)}</IndexTable.Cell>
              <IndexTable.Cell>{formatMoney(purchaseOrder.cost, userCurrency)}</IndexTable.Cell>
            </IndexTable.Row>
          ))}
        </IndexTable>
        <LegacyCard.Section>
          <LegacyStack alignment="center" distribution="center">
            <Pagination
              hasNext={hasNext}
              hasPrevious={hasPrev}
              nextKeys={[39]}
              onNext={nextPage}
              onPrevious={prevPage}
              previousKeys={[37]}
            />
          </LegacyStack>
        </LegacyCard.Section>
      </LegacyCard>
      <Footer pageTitle="purchase orders" url={purchaseOrderHelpPageUrl} />
    </Page>
  );

  return pageLoading ? <PurchaseOrdersIndexPageSkeleton /> : pageMarkup;
}

export default PurchaseOrdersIndex;
